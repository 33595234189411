import { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import { UnauthorizedPage } from '@/components/pages/error/UnauthorizedPage';
import { SharedAccessPage } from '@/components/pages/SharedForReview/SharedAccessPage';
import { useAccount } from '@/common/authentication/AuthenticationProvider';
import { NoClaimAccessPage } from '@/components/pages/authentication/NoClaimAccessPage';
import { SharedDownloadPage } from '@/components/pages/SharedDownload/SharedDownloadPage';
import { ProtectedAppRouting } from './ProtectedAppRouting';

export const RootRouting: FunctionComponent = () => {
  const { isMagicLinkUser } = useAccount();

  return (
    <Routes>
      <Route path="/shared-for-review/:accessKey" element={<SharedAccessPage />} />
      <Route path="/shared-for-download/:accessKey" element={<SharedDownloadPage />} />
      <Route path="/unauthorized" element={<UnauthorizedPage />} />
      <Route path="/no-claim-access" element={<NoClaimAccessPage />} />
      {!isMagicLinkUser && <Route path="/*" element={<ProtectedAppRouting />} /> }
      {isMagicLinkUser && <Route path="/*" element={<UnauthorizedPage />} /> }
    </Routes>
  );
};
