import { DatePickerProps } from '@instech/components';
import { DatePickerHighlighted } from '@/common/components/HighlightedFields';
import { useInvoiceSuggestion } from './utils';

type PaymentDateInputProps = {
  suggestion: string | undefined;
} & Partial<DatePickerProps>;
const PaymentDateInput = ({ suggestion, ...props }: PaymentDateInputProps) => {
  const highlight = useInvoiceSuggestion('paymentDate', suggestion);

  return (
    <DatePickerHighlighted
      name="paymentDate"
      label="Payment Date"
      putPlaceholderAbove
      data-highlight={highlight}
      showCalenderIcon
      inputIconPosition="after"
      {...props} />
  );
};

export default PaymentDateInput;
