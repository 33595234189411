import {
  ApplicationRole, ClaimStatement, Nullable
} from '@/types';
import { getClaimNumber } from '@/utils/claimStatement/getClaimNumber';
import { textOrDash } from '@/utils/text';
import { getNumberOfInvoices, getOccurrenceText } from './core/utils';

export type ClaimStatementTableData = {
  id: string;
  claimStatement: ClaimStatement;
  subrowCount: number;
  claimNumber: string;
  vesselName: string;
  fleetName: string;
  interest: string;
  claimDate: string;
  occurrences: string;
  claimAmount: number;
  currency: string;
  invoiceCounts: string;
  latestStatus: ClaimStatement['latestStatus']; // TODO: Refactor to be just string?
  lastEdited: string;
  lastEditedBy?: string; // LastEdited cell uses undefined status to determine tooltip rendering
  isFavorite: boolean;
}

/**
 * Intermediary mapping of table data to ensure consistency and handle
 * costly computations before the data enters the table itself.
 *
 * Also exposes the original ClaimStatement used for each table row, since some
 * of the table setup (ex. context menu options) requires this. This could be attempted
 * phased out to simplify, but should be fine for now.
 */
export const getClaimStatementTableData = (
  claimStatements: ClaimStatement[],
  role: Nullable<ApplicationRole>,
  favorites: string[]
): ClaimStatementTableData[] => (
  claimStatements.map(claimStatement => ({
    claimStatement, // include original data for ease of access
    id: claimStatement.id,
    subrowCount: claimStatement.occurrences.length,
    claimNumber: getClaimNumber(claimStatement.claimNumber, claimStatement.isExperimental),
    vesselName: claimStatement.vesselName,
    fleetName: claimStatement.fleetName,
    interest: claimStatement.interest,
    claimDate: claimStatement.claimDate,
    occurrences: getOccurrenceText(claimStatement.occurrences),
    claimAmount: claimStatement.claimAmount,
    currency: claimStatement.currency,
    invoiceCounts: getNumberOfInvoices(claimStatement, role),
    latestStatus: claimStatement.latestStatus,
    lastEdited: claimStatement.lastEdited,
    lastEditedBy: claimStatement.lastEditedBy,
    isFavorite: favorites.includes(claimStatement.id),
  }))
);
