import React, { FunctionComponent as FC, useEffect } from 'react';
import styled from 'styled-components';
import { cssVariables, useCSSVariableContext } from '@/common/providers/cssVariableProvider';
import { UserMenuLoader } from './core/UserMenuLoader';
import { PortalSelectorLoader } from './core/PortalSelectorLoader';
import { HeaderLogo } from './core/HeaderLogo';
import { HiddenInPrint } from '../../layout/Page';
import { Tabs } from './core/Tabs';

const HeaderRow = styled.div`
  @media print {
    position: static;
  }
  position: fixed;
  left: 0;
  right: 0;
  height:  ${cssVariables.headerHeight};
  padding-left: 40px;
  padding-right: 26px;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-areas: "logo tabs usermenu";
  grid-gap: 16px;
  background: ${props => props.theme.marineBlue};
  color: #fff;
  z-index: ${props => props.theme.zIndex.header};
`;

const RightPaddedHeaderRow = styled(HeaderRow)`
  padding: 0px 30px;
`;

const MenuSection = styled.div`
  grid-area: usermenu;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
  box-sizing: border-box;
`;

// Workaround: Remote components have different height,
// so they misalign when not centered in the header.
const MenuSectionInner = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  // Dirty fix to prevent role name from collapsing. Rendering on lower resolutions
  // could be improved here, but that needs to happen from H&N's side
  min-width: 336px;
`;

interface Props {
  showTabs?: boolean;
  emptyUserMenu?: boolean;
  showPortalSelector?: boolean;
  showUserMenu?: boolean;
  disableLink?: boolean
}
export const Header: FC<Props> = ({
  showTabs = true,
  emptyUserMenu = false,
  showPortalSelector = true,
  showUserMenu = true,
  disableLink = false
}) => {
  const { setHeaderHeight } = useCSSVariableContext();

  // On first render of component, force refresh of property
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setHeaderHeight(), []);

  if (!showPortalSelector && !showUserMenu) {
    return (
      <RightPaddedHeaderRow>
        <HeaderLogo disableLink={disableLink} />
        {showTabs && <Tabs />}
      </RightPaddedHeaderRow>
    );
  }

  if (!showPortalSelector) {
    return (
      <RightPaddedHeaderRow>
        <HeaderLogo />
        {showTabs && <Tabs />}
        <MenuSection>
          <UserMenuLoader showLinks={emptyUserMenu} />
        </MenuSection>
      </RightPaddedHeaderRow>
    );
  }

  if (!showUserMenu) {
    return (
      <RightPaddedHeaderRow>
        <HeaderLogo />
        {showTabs && <Tabs />}
        <MenuSection>
          <PortalSelectorLoader />
        </MenuSection>
      </RightPaddedHeaderRow>
    );
  }

  return (
    <HeaderRow>
      <HeaderLogo />
      <HiddenInPrint>
        {showTabs && <Tabs />}
        <MenuSection>
          <MenuSectionInner>
            <UserMenuLoader showLinks={!emptyUserMenu} />
            <PortalSelectorLoader />
          </MenuSectionInner>
        </MenuSection>
      </HiddenInPrint>
    </HeaderRow>
  );
};
