import { PageLoader } from '@instech/components';
import { AxiosResponse } from 'axios';
import {
  FunctionComponent, useEffect, useRef, useState
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { PageContent } from '@/common/layout/Page';
import { useTypedModal } from '@/common/modules/Modal';
import { useClaimStatement } from '@/services/claimStatementServices';
import { useInvoices } from '@/services/invoice/invoiceServices';
import {
  ClaimStatement, Guid, StagedInvoice
} from '@/types';
import { invoiceFileTypes } from '@/utils/file';
import { FileDropzone, SupportedFormat } from '@/common/components/FileDropzone';
import { cancelUploadModal } from './core/CancelUploadModal';
import { UploadPageHeading } from './core/UploadPageHeading';
import { UploadTable } from './core/UploadTable';
import { createHandleUpload, createHandleUploadNoFile } from './core/utils/createHandleUpload';
import { useInvoiceResponses } from './core/utils/useInvoiceResponses';
import { useInvoiceUpdates } from './core/utils/useInvoiceUpdates';
import { useSortedStagedInvoices } from './core/utils/useSortedStagedInvoices';

const someAreInProgress = (invoices: StagedInvoice[] | undefined) => {
  if (!invoices) return false;
  return invoices.some(invoice => invoice.uploadStatus === 'inProgress');
};

const someAreSuccess = (invoices: StagedInvoice[] | undefined) => {
  if (!invoices) return false;
  return invoices.some(invoice => invoice.uploadStatus === 'Success');
};

interface Props {
  claimStatement: ClaimStatement;
  batchId: Guid;
}
export const InvoiceUploader: FunctionComponent<Props> = ({ claimStatement, batchId }) => {
  const [isDirty, setIsDirty] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [stagedInvoices, setStagedInvoices] = useState<StagedInvoice[]>([]);
  const [invoiceResponses, setInvoiceResponses] = useState<[string, Promise<AxiosResponse>][]>([]);
  const { data, ...invoiceFuncs } = useInvoices(claimStatement.id, batchId);

  const navigate = useNavigate();
  const navigateToInvoice = () => navigate(`/claimstatements/${claimStatement.id}`);
  const navigateToBatchEdit = () => navigate(`/claimstatements/${claimStatement.id}/batch/${batchId}/invoices/1`);

  useInvoiceUpdates(stagedInvoices, setStagedInvoices, data);
  useInvoiceResponses(invoiceResponses, setStagedInvoices, stagedInvoices, setInvoiceResponses);
  useSortedStagedInvoices(stagedInvoices, setStagedInvoices);

  useEffect(() => {
    setIsDirty(!!data?.length);
    return () => setIsDirty(false);
  }, [data]);

  useEffect(() => {
    setCanSubmit(!someAreInProgress(stagedInvoices) && someAreSuccess(stagedInvoices));
    return () => setCanSubmit(false);
  }, [stagedInvoices]);

  const { open: openModal } = useTypedModal(cancelUploadModal({
    cancelBatch: async () => invoiceFuncs.cancelBatch(),
    navigateToInvoice
  }));

  // upload function extracted out in own handler file
  const handleUpload = createHandleUpload(invoiceFuncs.uploadInvoices, setStagedInvoices, stagedInvoices, setInvoiceResponses);
  const handleUploadNoFile = createHandleUploadNoFile(invoiceFuncs.uploadInvoiceNoFile, setStagedInvoices, stagedInvoices, setInvoiceResponses);

  const handleDelete = async (invoiceId: Guid) => {
    const status = stagedInvoices.find(invoice => invoice.id === invoiceId)?.uploadStatus;
    setStagedInvoices(stagedInvoices.filter(invoice => invoice.id !== invoiceId));
    if (status === 'Success') {
      void invoiceFuncs.deleteInvoice(invoiceId);
    }
  };

  // If there are no changes, just safely navigate away, otherwise
  // present modal and handle navigation through it
  const handleCancel = async () => {
    if (!isDirty) {
      navigateToInvoice();
    } else {
      openModal();
    }
  };

  const uploadInstructions = (
    <span>
      Please do not upload invoices containing personal identifiable
      information unless strictly necessary to support your claim.
    </span>
  );

  return (
    <div>
      <UploadPageHeading
        claimStatement={claimStatement}
        onSubmit={navigateToBatchEdit}
        onCancel={handleCancel}
        disableSubmit={!canSubmit}
      />
      <PageContent>
        <FileDropzone
          id="invoice-dropzone"
          handleUpload={handleUpload}
          handleUploadNoFile={handleUploadNoFile}
          instructions={uploadInstructions}
          acceptedTypes={invoiceFileTypes}
          acceptedTypesDisplay={<SupportedFormat />}
          multiple
        />
        <UploadTable
          uploadedInvoices={stagedInvoices}
          onRemoveItem={handleDelete}
        />
      </PageContent>
    </div>
  );
};

// Placing content in child component here so that claimStatement data
// is able to fetch before the actual page content loads in
export const UploadInvoicePage = () => {
  const { claimStatementId } = useParams();
  const { data: claimStatement } = useClaimStatement(claimStatementId);
  // Generate persisting batch-upload ID on page load
  const batchId = useRef(uuidv4());

  if (!claimStatementId || !batchId || !claimStatement) {
    return <PageLoader />;
  }

  return (
    <InvoiceUploader claimStatement={claimStatement} batchId={batchId.current} />
  );
};
