import { css } from 'styled-components';

export const TableCellHoverStyles = css`
    position: relative;
    &:before {
        content: '';
        position: absolute;
        inset: 0;
        opacity: 10%;
        visibility: hidden;
        pointer-events: none;
        background-color: ${props => props.theme.marineBlue};
    }
    &:hover:before {
        visibility: visible;
    }
`;
