import { LabelValuePair } from '@instech/components';
import { InferType } from 'yup';
import {
  FC, PropsWithChildren, RefObject
} from 'react';
import { updateAdjustersConsiderationRequestSchema } from './services/invoice/utils/adjustersConsiderationRequestSchema';
import {
  invoiceStates, invoiceStatuses, roles
} from './utils/constants';

// Defined helper for OrAnyString type below
type AnyString = (string & { anyT?: any });

/**
 * Extend component Props to include intrinsic HTMlElement Span props
 * (Interface Props extends IntrinsicSpanProps { })
 */
export type IntrinsicSpanProps = JSX.IntrinsicElements['span'] & {
  ref?: ((instance: HTMLSpanElement | null) => void) | RefObject<HTMLSpanElement> | null | undefined
};

/**
 * Wraps around a set of string literals, allowing for a type that will provide
 * intellisense on allowed strings while also satisfying generic 'string' type.
 * Ex: OrAnyString<"hello" | "world">
 */
export type OrAnyString<T extends string> = `${T}` | AnyString;

/**
 * Types T as T[] where one should expect there to be at least one element.
 */
export type AtLeastOne<T> = [T, ...T[]];

export type Guid = string;
export type ClaimStatementInvoiceId = { claimStatementId: Guid; invoiceId: Guid; };

export interface ApplicationRole {
  name: string;
  displayName: string;
}

export interface RoleApiRole {
  role: string;
  portal: string;
  displayName?: string;
}

export type UserRoles = typeof roles[keyof typeof roles];
export type InvoiceUploadStatus = 'Success' | 'inProgress' | 'Failed' | 'Duplicate' | 'SizeTooLarge' | 'InvalidFileType';
export type InvoiceState = keyof typeof invoiceStates;
export type PersonallyIdentifiableInformationOption = 'No' | 'Yes' | 'IncludesEu';

export type UpdateShareForReviewStatus = 'Success' | 'Failed';

export type InvoiceStatus = keyof typeof invoiceStatuses;
export const AvailableInvoiceStatuses = [invoiceStatuses.Open, invoiceStatuses.Closed];

export const UploadNoFileName = 'Claim cost without invoice document';

export interface UserInfo {
  id: Guid;
  fullName: string;
  company: string;
  companyId?: Guid;
  email: string;
  phoneNumber: string | null;
  title: string | null;
  hasAcceptedTerms?: boolean;
  hasSeenWelcomePage?: boolean;
}

export interface AssignedUser {
  userInfoId: Guid;
  fullName: string;
}

export type ClaimStatementStatus = 'Open' | 'Closed';

export interface ClaimStatementRequestQuery {
  claimsHandlerUserInfoIds: Guid[];
  status?: ClaimStatementStatus;
}

export interface UserInfoDto {
  id: Guid;
  fullName: string;
  company: string;
  companyId?: Guid;
  email: string;
  phoneNumber: string | null;
  title: string | null;
}

export interface ClaimsData {
  id: string;
  mdmExternalId?: string;
  insuranceId: string;
  insuranceYear: number;
  claimNumber: number;
  claimDate: string;
  currency: string;
  fleetName: string;
  vesselName: string;
  imoNumber: number;
  buildYear: number;
  class: string;
  claimsHandler?: Guid;
  claimEvent: string;
  interest: string;
  clsNo?: number;
}

export interface Participants {
  brokers: Guid[];
  surveyors: Guid[];
  owners: Guid[];
  claimsHandlers: Guid[];
}

export interface Occurrence {
  id: string;
  mdmExternalId?: string;
  insuranceId: string;
  name: string;
  claimDate: string;
  title: string;
  insuranceYear?: number;
  claimNumber?: number | null;
  location: string;
  interest: string;
}

export interface Indexed<T> {
  value: T,
  index: number
}

export const LatestOwnerStatusDict = {
  noStatus: 'NoStatus',
  claimStatementClosed: 'ClaimStatementClosed',
  claimStatementMarkedAsComplete: 'ClaimStatementMarkedAsComplete',
  invoicesToReview: 'InvoicesToReview',
  returnedInvoices: 'ReturnedInvoices',
  newClaimStatement: 'NewClaimStatement',
} as const;

export type LatestOwnerStatus = typeof LatestOwnerStatusDict[keyof typeof LatestOwnerStatusDict];

export const LatestSurveyorStatusDict = {
  noStatus: 'NoStatus',
  claimStatementClosed: 'ClaimStatementClosed',
  considerationMarkedAsComplete: 'ConsiderationMarkedAsComplete',
  newInvoicesToConsider: 'NewInvoicesToConsider',
  newClaimStatement: 'NewClaimStatement',
};

export type LatestSurveyorStatus = typeof LatestSurveyorStatusDict[keyof typeof LatestSurveyorStatusDict];

export const LatestClaimsHandlerStatusDict = {
  newClaimStatement: 'NewClaimStatement',
  noStatus: 'NoStatus',
  claimStatementClosed: 'ClaimStatementClosed',
  newInvoicesToConsider: 'NewInvoicesToConsider',
  ownersClaimCompleted: 'OwnersClaimCompleted',
  surveyorsConsiderationCompleted: 'SurveyorsConsiderationCompleted',
  adjustersConsiderationCompleted: 'AdjustersConsiderationCompleted',
};

export type LatestClaimsHandlerStatus = typeof LatestClaimsHandlerStatusDict[keyof typeof LatestClaimsHandlerStatusDict];

export interface ClaimStatementSettings {
  hasGeneralAverage: boolean;
}

export interface ClaimAddedUsers {
  owners: UserInfo[];
  brokers: UserInfo[];
  surveyors: UserInfo[];
  claimsHandlers: UserInfo[];
}

export interface NewClaimStatement {
  participants: ClaimAddedUsers;
  categories: string[];
  occurrences: Occurrence[];
  isExperimental: boolean;
  claimStatementSettings: ClaimStatementSettings;
}

export interface CreateClaimStatementRequest {
  participantRequest: Participants;
  categories: string[];
  occurrences: Occurrence[];
  isExperimental: boolean;
  claimStatementSettings: ClaimStatementSettings;
}

export interface ClaimStatement {
  id: string;
  insClaimId: string;
  insuranceId: string;
  insuranceYear: number;
  claimNumber: number;
  claimDate: string;
  currency: string;
  vesselName: string;
  fleetName: string;
  imoNumber: number;
  buildYear: number;
  class: string;
  interest: string;
  claimEvent: string;
  dateRegistered: string;
  lastEdited: string;
  lastEditedBy?: string;
  numberOfInvoices: number;
  brokers?: UserInfoDto[];
  surveyors?: UserInfoDto[];
  claimsHandler?: UserInfoDto;
  claimsHandlers?: UserInfoDto[];
  owner?: UserInfoDto;
  owners?: UserInfoDto[];
  categories: string[];
  repairPeriods: string[];
  clsNo: number;
  occurrences: Occurrence[];
  isExperimental: boolean;
  claimStatementSettings?: ClaimStatementSettings;
  ownersWorkComplete: boolean;
  claimAmount: number;
  hasAllocatedGeneralAverage: boolean;
  invoiceCounts: {
    total: number;
    sharedWithClaimsHandler: number;
    sharedWithSurveyor: number;
    returnedFromSurveyor: number;
    finished: number;
  };
  isClosed?: boolean;
  latestStatus: {
    owner: LatestOwnerStatus;
    surveyor: LatestSurveyorStatus;
    claimsHandler: LatestClaimsHandlerStatus;
  }
}

/**
 * Interface for just a file's metadata, minus the actual file blob,
 * for times when said metadata needs to be kept in memory but not
 * the file itself
 */
export interface FileMetadata {
  name: string;
  extension: string;
  size: number;
  lastModified: number;
}

export interface OwnerSummary {
  readonly invoicesNumerator: number | null;
  readonly invoicesDenominator: number | null;
  readonly sum: number | null;
  readonly claim: number | null;
  readonly ownersWork: number | null;
  readonly notAllocated: number | null;
}

export interface SurveyorSummary {
  readonly invoicesNumerator: number | null;
  readonly invoicesDenominator: number | null;
  readonly sum: number | null;
  readonly claim: number | null;
  readonly ownersWork: number | null;
  readonly generalExpenses: number | null;
  readonly adjusterToConsider: number | null;
}

export interface AdjusterSummary {
  readonly invoicesNumerator: number | null;
  readonly invoicesDenominator: number | null;
  readonly sum: number | null;
  readonly commonExpenses: number | null;
  readonly particularAverage: number | null;
  readonly totalOwnersWork: number | null;
  readonly generalAverage: number | null;
}

export interface ClaimsHandlerInvoiceSummary {
  readonly currency: string;
  readonly owner: OwnerSummary
  readonly surveyor: SurveyorSummary
  readonly adjuster: AdjusterSummary
}

// in some cases, returns for the optional record values
// from the API can be null instead of undefined ?
export interface InvoiceRecord {
  supplier?: string | null;
  order: number;
  invoiceNumber?: string | null;
  paymentDate?: string | null;
  totalAmount?: number | null;
  currency?: string | null;
  exchangeRate?: number | null;
  clientReference?: string | null;
  ownersComment?: string | null;
  notes?: string | null;
  category?: string | null;
  repairPeriod?: string | null;
  location?: string | null;
  claim?: number | null;
  ownersWork?: number | null;
  isComplete: boolean;
  claimInPolicyCurrency?: number;
  ownersWorkInPolicyCurrency?: number;
  generalExpenses?: number | null;
  adjustersConsideration?: number | null;
  personallyIdentifiableInformation?: PersonallyIdentifiableInformationOption | null;
  personallyIdentifiableInformationDescription?: string | null;
  paidAmount?: number | null;
}

// Interface detailing a split
// without any additional properties used for form management.
export interface SurveyorsConsiderationSplit {
  id: Guid;
  category?: string;
  generalExpenses?: number | string | null;
  generalExpensesTag?: string | null;
  claimConsideration?: number | string | null;
  claimConsiderationTag?: string | null;
  ownersWorkConsideration?: number | string | null;
  occurrenceId?: string;
  adjustersConsideration?: number | string | null;
  surveyorsComment?: string | null;
  internalNotes?: string | null;
  initialClaim?: number | null;
  initialOwnersWork?: number | null;
  isConsidered?: boolean;
  deviationPresent?: boolean;
}

export interface SurveyorsConsideration {
  splits: SurveyorsConsiderationSplit[];
  deviationPresent?: boolean;
  isConsidered: boolean;
  generalExpenses?: number | string | null;
  claimConsideration?: number | string | null;
  ownersWorkConsideration?: number | string | null;
  adjusterToConsider?: number | string | null;
  surveyorsComment?: string | null;
}

// Interface detailing a split when used for a PUT request,
// without any additional properties used for form management.
export interface AdjustersConsiderationSplit {
  id: Guid;
  category?: string | null;
  repairPeriod?: string;
  commonExpenses?: string | number | null;
  commonExpensesTag?: string | null;
  ownersWorkConsideration?: string | number | null;
  ownersWorkConsiderationTag?: string | null;
  particularAverage?: string | number | null;
  particularAverageTag?: string | null;
  generalAverage?: string | number | null;
  isGeneralAverageConsidered?: boolean;
  isCommonExpensesConsidered?: boolean;
  isOwnersWorkConsiderationConsidered?: boolean;
  isParticularAverageConsidered?: boolean;
  adjustersComment?: string | null;
  internalNotes?: string | null;
  initialParticularAverage?: number | null;
  initialOwnersWork?: number | null;
  initialCommonExpenses?: number | null;
  isConsidered?: boolean;
  deviationPresent?: boolean;
  occurrenceId?: string;
  surveyorSplitId?: Guid;
  isDeleted?: boolean;
  isOutdated?: boolean;
  isSuggested?: boolean;
}

export interface AdjustersConsideration {
  splits: AdjustersConsiderationSplit[];
  deviationPresent?: boolean;
  isConsidered?: boolean;
  isOutdated?: boolean;
  commonExpenses?: number | null;
  ownersWorkConsideration?: number | null;
  particularAverage?: number | null;
  generalAverage?: string | number | null;
  adjustersComment?: string | null;
}

export interface InvoiceStateMetadata {
  state: InvoiceState;
  claimStatementId: Guid;
  id: Guid;
  adjusted?: boolean;
  isSharedForReview?: boolean
}

export interface Invoice {
  id: Guid;
  claimStatementId: Guid;
  uploadBatchId: Guid;
  etag: string;
  path?: string | null;
  name: string;
  sizeInBytes?: number;
  uploadStatus: InvoiceUploadStatus,
  record: InvoiceRecord;
  surveyorsConsideration?: SurveyorsConsideration,
  adjustersConsideration: AdjustersConsideration,
  ownersReviewComment?: string;
  ownersReviewDraftComment?: string;
  state: InvoiceState;
  isSharedForReview?: boolean;
}

export interface InvoiceLink {
  url: string;
}

export interface InvoiceOverviewBase {
  totalInvoices: number;
  total: number | null;
  claim: number | null;
  ownersWork: number | null;
  currency: string;
  invoices: Invoice[]
}

export interface InvoiceOverview extends InvoiceOverviewBase {
  incompleteInvoices: number;
}

export interface LatestInstructions {
  instructions: string;
}

export interface InvoiceOverviewSurveyor extends InvoiceOverviewBase {
  surveyorTotalGeneralExpenses?: number;
  surveyorTotalClaim?: number;
  surveyorTotalOwnersWork?: number;
  surveyorAdjustorToConsider?: number;
}

export interface StagedInvoice {
  id: string;
  name: string;
  sizeInBytes: number;
  uploadStatus: InvoiceUploadStatus;
  order?: number;
}

export interface Categories {
  categories: string[];
}

export interface RepairPeriods {
  repairPeriods: string[];
}

export interface AdditionalInformationForm {
  category?: LabelValuePair;
  repairPeriod?: LabelValuePair;
  location?: LabelValuePair;
  personallyIdentifiableInformation?: LabelValuePair;
  personallyIdentifiableInformationDescription?: string;
}

interface AdditionalInformationRequest {
  category?: string;
  location?: string;
  repairPeriod?: string;
  personallyIdentifiableInformation?: string;
  personallyIdentifiableInformationDescription?: string;
}

export interface OwnersClaim {
  invoiceNumber?: string;
  paymentDate?: Date | string | null;
  totalAmount?: string;
  exchangeRate?: string;
  paidAmount?: string;
  clientReference?: string;
  ownersComment?: string;
  notes?: string;
}

/**
 * For Owner's initial submission of owner's claim on invoice.
 */
export interface OwnersClaimForm extends OwnersClaim {
  supplier?: LabelValuePair;
  currency?: LabelValuePair;
}

interface OwnersClaimRequest extends OwnersClaim {
  supplier?: string;
  currency?: string;
  etag: string;
}

interface TotalValues {
  claim?: string;
  ownersWork?: string;
}

/**
 * For allowing Claims Handler and Surveyor to later update
 * owner's claim by editing specific fields.
 */
export interface UpdateOwnersRecordForm {
  supplier?: LabelValuePair;
  exchangeRate?: string | number;
  paymentDate?: Date | string | null;
  paidAmount?: string | number;
}

export interface UpdateOwnersRecordRequest extends UpdateOwnersRecordForm {
  etag: string;
}

export interface TotalValuesForm extends TotalValues { }
export interface TotalValuesRequest extends TotalValues { }

// This interface includes a number of props that are relevant for the
// Surveyor's Consideration split form but not relevant when performing
// a GET or PUT of the consideration.
export interface SurveyorsConsiderationSplitForm {
  id: Guid;
  form: {
    arrayId: string;
    hasInternalNotes: boolean;
    startCollapsed: boolean;
    claimConsidered?: boolean;
    ownersWorkConsidered?: boolean;
  }
  category?: LabelValuePair;
  repairPeriod?: LabelValuePair;
  generalExpenses?: number | string | null;
  generalExpensesTag?: LabelValuePair;
  claimConsideration?: number | string | null;
  claimConsiderationTag?: LabelValuePair;
  ownersWorkConsideration?: number | string | null;
  adjustersConsideration?: number | string | null;
  surveyorsComment?: string | null;
  internalNotes?: string | null;
  initialClaim?: number | null;
  initialOwnersWork?: number | null;
  isConsidered?: boolean;
  occurrence?: LabelValuePair;
}

export interface SurveyorsConsiderationForm {
  splits: SurveyorsConsiderationSplitForm[];
  deviationPresent?: boolean;
}
export interface SurveyorsConsiderationRequest {
  splits: SurveyorsConsiderationSplit[];
  deviationPresent?: boolean;
  etag: string;
}

export interface UpdateSurveyorsConsiderationForm extends AdditionalInformationForm, SurveyorsConsiderationForm { }
export interface UpdateSurveyorsConsiderationRequest extends
  AdditionalInformationRequest, SurveyorsConsiderationRequest { }

// Interface with a number of props that are relevant for the
// Adjuster's Consideration split form, but not relevant when
// performing a GET or PUT of the consideration.
export interface AdjustersConsiderationSplitForm {
  form: {
    arrayId: string;
    hasInternalNotes: boolean;
    startCollapsed: boolean;
    particularAvgConsidered?: boolean;
    ownersWorkConsidered?: boolean;
    generalAverageConsidered?: boolean;
    commonExpensesConsidered?: boolean;
    isOutdated?: boolean;
  }
  id: Guid,
  category?: LabelValuePair;
  repairPeriod?: LabelValuePair;
  commonExpenses?: string | number | null;
  commonExpensesTag?: LabelValuePair;
  ownersWorkConsideration?: string | number | null;
  ownersWorkConsiderationTag?: LabelValuePair;
  particularAverage?: string | number | null;
  particularAverageTag?: LabelValuePair;
  generalAverage?: string | number | null;
  adjustersComment?: string | null;
  internalNotes?: string | null;
  initialParticularAverage?: number | null;
  initialOwnersWork?: number | null;
  initialCommonExpenses?: number | null;
  initialCategory?: string | null;
  initialOccurrenceId?: Guid,
  occurrence: LabelValuePair;
  surveyorSplitId?: Guid;
  isDeleted?: boolean;
  isSuggested?: boolean;
}

export interface AdjustersConsiderationForm {
  splits: AdjustersConsiderationSplitForm[];
  isConsidered?: boolean;
}

export interface UpdateAdjustersConsiderationForm extends AdditionalInformationForm, AdjustersConsiderationForm { }
export interface UpdateAdjustersConsiderationRequest extends InferType<typeof updateAdjustersConsiderationRequestSchema> { }

export interface UpdateInvoiceForm extends
  AdditionalInformationForm, OwnersClaimForm, TotalValuesForm { }
export interface UpdateInvoiceRequest extends
  OwnersClaimRequest, AdditionalInformationRequest, TotalValuesRequest { }

export interface ClaimStatementFavourites {
  claimStatementIds: string[];
}

export interface CreateInvoiceReviewRequest {
  IsSharedForReview: boolean;
  invoiceIdList: string[];
}

export interface UpdateInvoiceReviewResponse {
  invoiceId: string;
  reviewStatus: UpdateShareForReviewStatus;
}

export interface AccessKey {
  key: string;
  claimStatementId: Guid;
}

export interface ClaimSearchData {
  claimNumber?: string | number;
  insuranceYear?: string | number;
  claimStatementId?: Guid;
}

export type Nullable<T> = T | undefined | null;

export const adjusterCommonExpensesTags: LabelValuePair[] = [
  { label: 'Docking', value: 'Docking' },
  { label: 'Shifting to/from dry dock', value: 'ShiftingDryDock' },
  { label: 'Wharfage', value: 'Wharfage' },
  { label: 'Ballast water for undocking', value: 'BallastWaterForUndocking' },
  { label: 'Interest - Not subject to deductible', value: 'InterestNotSubjectToDeductible' },
];

export const adjusterParticularAverageTags: LabelValuePair[] = [
  { label: 'Cost incurred in expediting repairs', value: 'CostIncurred' },
  { label: 'Temporary repairs', value: 'TemporaryRepairs' },
  { label: 'Interest - Not subject to deductible', value: 'InterestNotSubjectToDeductible' },
  { label: 'Not basis for apportionment', value: 'NotBasisForApportionment' }
];

export const adjusterOwnersWorkTags = [
  { label: 'Basis for apportionment', value: 'BasisForApportionment' }
];

export const surveyorGeneralExpensesTags: LabelValuePair[] = [
  { label: 'Docking', value: 'Docking' },
  { label: 'Shifting to/from dry dock', value: 'ShiftingDryDock' },
  { label: 'Wharfage', value: 'Wharfage' },
  { label: 'Ballast water for undocking', value: 'BallastWaterForUndocking' }
];

export const surveyorClaimConsiderationTags: LabelValuePair[] = [
  { label: 'Cost incurred in expediting repairs', value: 'CostIncurred' },
  { label: 'Temporary repairs', value: 'TemporaryRepairs' }
];

export interface AdjustmentPdf {
  id: Guid,
  filename: string,
  isDraft: boolean,
  createdAt: string
}

export interface GenerateAdjustmentPdfRequest {
  isDraft: boolean
}

export interface GenerateAdjustmentPdfResponse {
  adjustmentPdfId: Guid
}

export interface AdjustmentPdfDownloadLink {
  url: string,
  expiresAt: string
}

export type FCWithChildren<T = unknown> = FC<PropsWithChildren<T>>

export type FCWC<T = unknown> = FCWithChildren<T>
