import React from 'react';
import {
  InputAttributes, NumericFormat, NumericFormatProps
} from 'react-number-format';
import { getParsedDecimalAsStringWithGivenPrecision } from '@/utils/number';
import { validateLimitedNumberInput } from '@/utils/input';

type Props<T = InputAttributes> = NumericFormatProps<T> & {
  setValue: (value: string) => void
}

export const NumberInput = <T, >(props: Props<T>) => {
  const handleCopy = (evt: any) => {
    evt.preventDefault();
    evt.clipboardData.setData('text/plain', props.value?.toString() ?? '');
  };

  const handlePaste = (evt: React.ClipboardEvent<HTMLInputElement>) => {
    evt.stopPropagation();
    evt.preventDefault();
    const clipboardData = evt.clipboardData || (window as any).clipboardData;
    const pastedData = clipboardData.getData('text');

    const normalizedVal = getParsedDecimalAsStringWithGivenPrecision(pastedData, props.decimalScale);

    const { value: validVal, validInput } = validateLimitedNumberInput(normalizedVal, { maxDecimals: props.decimalScale });

    if (validInput) {
      props.setValue(validVal);
    }
  };

  /*
   * We don't want to pass the setValue to the child component
   * This looks kind of awful, but it was the only way that I found
   * to omit the setValue from the childProps without messing up the
   * types and making typescript angry.
   */

  const childProps = { ...props, setValue: undefined };
  delete childProps.setValue;

  return (
    <NumericFormat
      thousandSeparator
      onCopy={handleCopy}
      onPaste={handlePaste}
      isAllowed={values =>
        !values.floatValue ||
        (values.floatValue <= Number.MAX_SAFE_INTEGER && values.floatValue >= Number.MIN_SAFE_INTEGER)}
      {...childProps}
    />
  );
};
