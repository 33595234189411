import { getInitials } from '@/common/components/Table/utils';
import {
  ApplicationRole, ClaimStatement, Guid, Nullable, Occurrence,
  UserInfoDto
} from '@/types';
import { roles } from '@/utils/constants';
import { getFriendlyDate } from '@instech/components';
import { textOrDash } from '@/utils/text';

type Roles = keyof typeof roles;

export const getNumberOfInvoices = (claimStatement: ClaimStatement, role: Nullable<ApplicationRole>) => {
  const { finished, sharedWithClaimsHandler, returnedFromSurveyor, sharedWithSurveyor, total } = claimStatement.invoiceCounts;

  switch (role?.name as Roles) {
    case roles.Owner:
    case roles.Broker:
      return `${sharedWithClaimsHandler} / ${total}`;
    case roles.ClaimsHandler:
      return `${finished} / ${sharedWithClaimsHandler} / ${total}`;
    case roles.Surveyor:
      return `${returnedFromSurveyor} / ${sharedWithSurveyor}`;
    default:
      return '-';
  }
};

export const getLastUpdate = (claimStatement: ClaimStatement) => (
  (claimStatement.lastEdited && claimStatement.lastEditedBy)
    ? `${getFriendlyDate(claimStatement.lastEdited)} / ${getInitials(claimStatement.lastEditedBy)}`
    : '-'
);

export const getIsAssignedToUser = (userId?: Guid, claimsHandlers: UserInfoDto[] = []): boolean => (
  !!userId && claimsHandlers.map(x => x.id).includes(userId)
);

export const getOccurrenceText = (occurrences: Occurrence[]) => occurrences.length > 1 ? 'Multiple' : textOrDash(occurrences[0]?.name);
