import { invoiceDataValidationSchema } from '@/components/pages/invoice/core/EditInvoice/utils/validationSchema';
import {
  ClaimsHandlerInvoiceSummary, CreateInvoiceReviewRequest, Guid,
  Invoice,
  InvoiceLink,
  InvoiceOverview,
  InvoiceOverviewSurveyor,
  InvoiceUploadStatus,
  LatestInstructions,
  UpdateInvoiceForm,
  UpdateInvoiceRequest, UpdateInvoiceReviewResponse, UpdateOwnersRecordForm,
  UpdateOwnersRecordRequest
} from '@/types';
import { invoiceFileTypes, validateFiletypeAndSize } from '@/utils/file';
import { useCallback } from 'react';
import useSWR from 'swr';
import {
  deleteAsync, patchAsync, putAsync,
} from '../../client';
import { createUploadInvoiceNoFile, createUploadInvoices } from '../utils/createUploadInvoices';
import {
  apiRoute,
  apiRouteBatchInvoices,
  apiRouteInvoice,
  apiRouteInvoiceLink,
  apiRouteInvoiceSharedForReview,
  apiRouteLatestOwnersInstructions,
  apiRouteLatestSurveyorsInstructions,
  apiRouteUpdateOwnersRecord
} from '../utils/invoiceApiRoute';
import { queries } from '../utils/invoiceStateQueries';
import { createUpdateInvoiceAdjuster } from './updateInvoiceAdjuster';
import { createUpdateInvoiceSurveyor } from './updateInvoiceSurveyor';
import { sanitizeInvoiceValues } from '../utils/sanitizeInvoiceValues';

export type InvoiceRequestStatus = 'error' | 'success' | 'conflict';
type InvoiceLockStatus = 'locked' | 'notlocked';

export interface UpdateAdjustersInvoiceResponse {
  status: InvoiceRequestStatus;
  invoice: Invoice
}

export interface InvoiceReplaceRequestStatus {
  invoice?: Invoice,
  uploadStatus: InvoiceUploadStatus | 'Uninitialized',
}

export const useInvoiceLink = (claimStatementId?: string, invoiceId?: string) => {
  const swrRoute = claimStatementId && invoiceId ? apiRouteInvoiceLink(claimStatementId, invoiceId) : null;
  return useSWR<InvoiceLink>(swrRoute);
};

/**
 * Hook to fetch data for an invoice, given its ID and the ID of its claim statement.
 * Provides functions to update invoice generally, update invoice for surveyor, and
 * replace invoice file (provided through this for ease of access and SWR mutation).
 */
export const useInvoice = (claimStatementId?: string, invoiceId?: string) => {
  const url = (claimStatementId && invoiceId) ? apiRouteInvoice(claimStatementId, invoiceId) : null;
  const { data, mutate, error } = useSWR<Invoice>(url);

  /**
   * Call to update invoice with new provided data.
   */
  const updateInvoice = async (updateForm: UpdateInvoiceForm, invoice: Invoice): Promise<Invoice | InvoiceRequestStatus> => {
    const sanitizedValues = sanitizeInvoiceValues(updateForm);

    // Up-cast to not include internal LabelValue types
    const requestValues: UpdateInvoiceRequest = {
      ...sanitizedValues,
      etag: invoice.etag,
      currency: sanitizedValues.currency?.value,
      category: sanitizedValues.category?.value,
      repairPeriod: sanitizedValues.repairPeriod?.value,
      supplier: sanitizedValues.supplier?.value,
      location: sanitizedValues.location?.value,
      personallyIdentifiableInformation: updateForm.personallyIdentifiableInformation?.value
    };

    const response = await putAsync(apiRouteInvoice(claimStatementId!, invoiceId!), requestValues);

    if (response.status === 200) {
      await mutate(response.data);
      return response.data;
    }
    if (response.status === 409 || response.status === 403) {
      return 'conflict';
    }
    return 'error';
  };

  /**
   * Call to update invoice, specifically for parts and data that can be altered by a Surveyor
   * when they are making the Surveyor's Considerations.
   */
  const updateInvoiceSurveyor = createUpdateInvoiceSurveyor(claimStatementId!, invoiceId!, mutate);

  /**
   * Call to update invoice, specifically for parts and data that can be altered by a Adjuster
   * when they are making the Adjuster's Considerations.
   */
  const updateInvoiceAdjuster = createUpdateInvoiceAdjuster(claimStatementId!, invoiceId!, mutate);

  const updateOwnersRecord = async (updateForm: UpdateOwnersRecordForm, invoice: Invoice, returnData: boolean = false) => {
    const castedUpdateInvoice = invoiceDataValidationSchema.cast(updateForm, { assert: false });
    // Up-cast to not include internal LabelValue types
    const requestValues: UpdateOwnersRecordRequest = {
      ...castedUpdateInvoice,
      etag: invoice.etag,
      supplier: castedUpdateInvoice.supplier?.value
    };

    const response = await putAsync(apiRouteUpdateOwnersRecord(claimStatementId!, invoiceId!), requestValues);

    if (response.status === 200) {
      const newData: Invoice = { ...response.data, state: data?.state ?? response.data.state };
      await mutate(newData);
      return returnData ? response.data : 'success';
    }
    return returnData ? response.data : 'error';
  };

  /**
   * Call to replace invoice file with a new file.
   */
  const replaceInvoice = async (invoice: File): Promise<InvoiceReplaceRequestStatus> => {
    const errorMessage = validateFiletypeAndSize(invoice, invoiceFileTypes) as InvoiceUploadStatus;
    if (errorMessage) {
      return { uploadStatus: errorMessage };
    }

    const formData = new FormData();
    formData.append('invoiceFile', invoice as Blob);

    const response = await putAsync(`${apiRouteInvoice(claimStatementId!, invoiceId!)}/replace`, formData);
    const { uploadStatus } = response.data;

    if (response.status === 200) {
      if (response.data.uploadStatus === 'Success') {
        const responseData = await mutate();
        return { invoice: responseData, uploadStatus };
      }
      return { uploadStatus };
    }

    return { uploadStatus };
  };

  const lockInvoice = useCallback(async (): Promise<InvoiceLockStatus> => {
    const response = await putAsync(`${apiRouteInvoice(claimStatementId!, invoiceId!)}/lock`);
    if (response.status === 409) {
      return 'notlocked';
    }

    return 'locked';
  }, [claimStatementId, invoiceId]);

  const unlockInvoice = useCallback(async () => {
    await deleteAsync(`${apiRouteInvoice(claimStatementId!, invoiceId!)}/lock`);
  }, [claimStatementId, invoiceId]);

  return {
    data,
    updateInvoice,
    updateInvoiceSurveyor,
    updateInvoiceAdjuster,
    updateOwnersRecord,
    replaceInvoice,
    lockInvoice,
    unlockInvoice,
    error,
    mutate
  };
};

export const useInvoiceSharedForReview = (claimStatementId: string | undefined, invoiceId: string | undefined) => {
  const url = (claimStatementId && invoiceId) ? apiRouteInvoiceSharedForReview(claimStatementId, invoiceId) : null;
  const { data, mutate } = useSWR<Invoice>(url);

  return { data, mutate };
};

export const useInvoicesOverview = (claimStatementId: string | undefined) => {
  const swrRoute = claimStatementId ? `${apiRoute(claimStatementId)}?${queries.OwnerEditable}` : null;
  const { data, mutate } = useSWR<InvoiceOverview>(swrRoute);

  return { data, mutate };
};

export const useSubmittedInvoicesOverview = (claimStatementId: string | undefined) => {
  const swrRoute = claimStatementId ? `${apiRoute(claimStatementId)}?${queries.OwnerViewable}` : null;
  return useSWR<InvoiceOverview>(swrRoute);
};

export const useLatestSurveyorsInstructions = (claimStatementId: string | undefined, invoiceId: string | undefined) => {
  const swrRoute = claimStatementId && invoiceId ? apiRouteLatestSurveyorsInstructions(claimStatementId, invoiceId) : null;
  return useSWR<LatestInstructions>(swrRoute);
};

export const useLatestOwnersInstructions = (claimStatementId: string | undefined, invoiceId: string | undefined) => {
  const swrRoute = claimStatementId && invoiceId ? apiRouteLatestOwnersInstructions(claimStatementId, invoiceId) : null;
  return useSWR<LatestInstructions>(swrRoute);
};

export const useClaimsHandlerInvoicesOverview = (claimStatementId: string | undefined) => {
  const swrRoute = claimStatementId ? `${apiRoute(claimStatementId)}?${queries.ClaimsHandlerViewable}` : null;
  return useSWR<InvoiceOverview>(swrRoute);
};

export const useSurveyorEditableInvoicesOverview = (claimStatementId: string | undefined) => {
  const swrRoute = claimStatementId ? `${apiRoute(claimStatementId)}?${queries.SurveyorEditable}` : null;
  return useSWR<InvoiceOverviewSurveyor>(swrRoute);
};

export const useSurveyorReadonlyInvoicesOverview = (claimStatementId: string | undefined) => {
  const swrRoute = claimStatementId ? `${apiRoute(claimStatementId)}?${queries.SurveyorViewable}` : null;
  return useSWR<InvoiceOverviewSurveyor>(swrRoute);
};

export const useSurveyorSubmittedInvoicesOverview = (claimStatementId: string | undefined) => {
  const swrRoute = claimStatementId ? `${apiRoute(claimStatementId)}?${queries.SurveyorSubmitted}` : null;
  return useSWR<InvoiceOverviewSurveyor>(swrRoute);
};

export const useInvoicesSummary = (claimStatementId: string | undefined) => {
  const swrRoute = claimStatementId ? `${apiRoute(claimStatementId)}/summary` : null;
  return useSWR<ClaimsHandlerInvoiceSummary>(swrRoute);
};

export const deleteInvoice = async (claimStatementId: Guid, invoiceId: Guid): Promise<InvoiceRequestStatus> => {
  try {
    const result = await deleteAsync(`${apiRouteInvoice(claimStatementId, invoiceId)}`);
    if (result!.status === 204) {
      return 'success';
    }
    return 'error';
  } catch (e) {
    return 'error';
  }
};

export const useInvoices = (claimStatementId: Guid, batchId: Guid) => {
  const { data, mutate, error } = useSWR<Invoice[]>(`${apiRouteBatchInvoices(claimStatementId, batchId)}`, {
    refreshInterval: 1000
  });

  const uploadInvoices = createUploadInvoices(claimStatementId, batchId);
  const uploadInvoiceNoFile = createUploadInvoiceNoFile(claimStatementId, batchId);

  const deleteInvoiceFromBatch = async (invoiceId: Guid): Promise<InvoiceRequestStatus> => {
    const deleteStatus = await deleteInvoice(claimStatementId, invoiceId);
    if (deleteStatus === 'success') {
      await mutate(prev => prev?.filter(i => i.id !== invoiceId));
    }

    return deleteStatus;
  };

  const cancelBatch = async () => {
    const result = await putAsync(`${apiRouteBatchInvoices(claimStatementId, batchId)}/cancel`);

    if (result) {
      return 'success';
    }
    return 'error';
  };

  return { data, uploadInvoices, uploadInvoiceNoFile, deleteInvoice: deleteInvoiceFromBatch, cancelBatch, error };
};

export const useInvoiceBatch = (claimStatementId: Guid, batchId: Guid) =>
  useSWR<Guid[]>(`${apiRouteBatchInvoices(claimStatementId, batchId)}/invoiceIds`);

export const shareInvoicesForReview = async (claimStatementId: Guid, shareRequest: CreateInvoiceReviewRequest) => {
  const result = await patchAsync<UpdateInvoiceReviewResponse[]>(apiRoute(claimStatementId), shareRequest);
  return result.data;
};
