import { AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { StagedInvoice } from '@/types';
import { invoiceFileTypes, validateFiletypeAndSize } from '@/utils/file';
import { postFormAsync } from '@/services/client';

export const createUploadInvoices = (claimStatementId: string, batchId: string) =>
  (invoices: File[]): [StagedInvoice, Promise<AxiosResponse> | undefined][] =>
    invoices.map(invoice => {
      const invoiceId = uuidv4();
      const staged: Partial<StagedInvoice> = { id: invoiceId, name: invoice.name, sizeInBytes: invoice.size };

      const errorMessage = validateFiletypeAndSize(invoice, invoiceFileTypes);
      if (errorMessage) {
        return [{ ...staged, uploadStatus: errorMessage } as StagedInvoice, undefined];
      }

      const formData = new FormData();
      formData.append('file', invoice as Blob);
      const response = postFormAsync(`/claimstatements/${claimStatementId}/invoices/${invoiceId}/batches/${batchId}`, formData);
      return [{ ...staged, uploadStatus: 'inProgress' } as StagedInvoice, response];
    });

export const createUploadInvoiceNoFile = (claimStatementId: string, batchId: string) =>
  (): [StagedInvoice, Promise<AxiosResponse> | undefined] => {
    const invoiceId = uuidv4();

    const staged: Partial<StagedInvoice> = { id: invoiceId };

    const response = postFormAsync(`/claimstatements/${claimStatementId}/invoices/${invoiceId}/batches/${batchId}`, null);
    return [{ ...staged, uploadStatus: 'inProgress' } as StagedInvoice, response];
  };
