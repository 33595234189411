import { createRoot } from 'react-dom/client';
import {
  DefaultErrorMessage, ErrorBoundary, AppInsightsProvider
} from '@instech/components';
import { createBrowserHistory } from 'history';
import { pdfjs } from 'react-pdf';
import 'pdfjs-dist/build/pdf.worker.entry';
import 'react-app-polyfill/stable';
import './common/fonts.scss';
import './common/custom.css';
import 'normalize.css';
import { ThemeProvider } from 'styled-components';
import { App } from './App';
import { WebsiteDownProvider } from './common/providers/websiteDownProvider';
import { theme } from './utils/style/theme';
import { CSSVariableProvider } from './common/providers/cssVariableProvider';
import { FontPreloadProvider } from './common/providers/fontPreloadProvider';

const rootElement = document.getElementById('root');
const appInsightsApplicationName = 'Insify.ClaimStatement.Web';

const browserHistory = createBrowserHistory({ window });

pdfjs.GlobalWorkerOptions.workerSrc = (window as any).pdfjsWorker;

const getCookieValue = (name: string): string => {
  const nameLenPlus = (name.length + 1);
  return document.cookie
    .split(';')
    .map(c => c.trim())
    .filter(cookie => cookie.substring(0, nameLenPlus) === `${name}=`)
    .map(cookie => decodeURIComponent(cookie.substring(nameLenPlus)))[0];
};

// opt into Concurrent Mode
// @ts-ignore
createRoot(rootElement).render(
  <AppInsightsProvider
    application={appInsightsApplicationName}
    browserHistory={browserHistory}
    connectionString={getCookieValue('AppInsightsConnectionString')}>
    <ErrorBoundary component={DefaultErrorMessage}>
      <ThemeProvider theme={theme}>
        <FontPreloadProvider>
          <CSSVariableProvider>
            <WebsiteDownProvider>
              <App />
            </WebsiteDownProvider>
          </CSSVariableProvider>
        </FontPreloadProvider>
      </ThemeProvider>
    </ErrorBoundary>
  </AppInsightsProvider>
);
