import React, { FC } from 'react';
import {
  DatePicker, SlimButton, TextField
} from '@instech/components';
import { FormRow } from '@/common/components/Form/FormRow/FormRow';
import {
  MoveLayerDown, Refresh, Trash
} from '@instech/icons';
import styled from 'styled-components';
import { Occurrence } from '@/types';
import { InfoWithLabel } from '@/common/components/Text';
import { useTypedModal } from '@/common/modules/Modal';
import { createOccurrenceFromInsModal } from '@/components/pages/claim/core/ClaimStatementForm/Occurrences';

interface TextButtonProps {
  icon: any;
  text: string;
  disabled?: boolean;
  onClick?: () => void;
}

const StyledButton = styled(SlimButton)`
  & span {
    font-size: 14px;
  }
`;

const TextButton: FC<TextButtonProps> = ({
  icon,
  text,
  onClick,
  disabled
}) => (
  <StyledButton variant="tertiary" startIcon={icon} onClick={onClick} disabled={disabled}>
    {text}
  </StyledButton>
);

interface SyncButtonProps {
  onGetOccurrenceDataFromIns?: () => void;
  hasInsData: boolean;
}

const SyncButton: FC<SyncButtonProps> = ({
  hasInsData,
  onGetOccurrenceDataFromIns
}) => {
  if (hasInsData) {
    return (
      <TextButton disabled icon={<Refresh width="18px" height="18px" />} text="RE-SYNC" />
    );
  }
  return (
    <TextButton onClick={onGetOccurrenceDataFromIns} icon={<MoveLayerDown width="18px" height="18px" />} text="GET CLAIM FROM INS+" />
  );
};

const Title = styled.div`
  display: flex;
  align-items: baseline;
  color: ${props => props.theme.marineBlue};
  justify-content: space-between;
  font-size: 16px;
  padding-bottom: 5px;
  border-bottom: ${props => `2px ${props.theme.marineBlue60} solid`};
  font-weight: bold;
  margin-bottom: 20px;
`;

const OccurrenceRow = styled(FormRow)`
  align-items: center;
`;

const InsInfo = styled(InfoWithLabel)`
  color: ${props => props.theme.marineBlue};
`;

interface ItemProps {
  index: number,
  occurrence: Occurrence,
  currency: string,
  isExperimentalClaimStatement: boolean,
  updateOccurrence: (index: number, update: Occurrence) => void,
  removeOccurrence: (index: number) => void,
  disableRemove: boolean
}

export const OccurrenceItem: FC<ItemProps> = ({
  index,
  occurrence,
  currency,
  isExperimentalClaimStatement,
  updateOccurrence,
  removeOccurrence,
  disableRemove
}) => {
  const hasInsData = occurrence.claimNumber !== undefined;

  const { open: openUpdateOccurrenceWithDataFromInsModal } = useTypedModal(createOccurrenceFromInsModal({
    modalTitle: 'Get Claim from INS+',
    occurrenceOrderNumber: index + 1,
    currency,
    isExperimentalClaimStatement,
    onSubmit: claimsData => updateOccurrence(index, claimsData)
  }));
  return (
    <div>
      <Title>
        <div>
          Occurrence #
          {index+1}
        </div>
        <div>
          <SyncButton hasInsData={hasInsData} onGetOccurrenceDataFromIns={openUpdateOccurrenceWithDataFromInsModal} />
          <TextButton icon={<Trash />} text="DELETE" onClick={() => removeOccurrence(index)} disabled={disableRemove} />
        </div>
      </Title>
      <OccurrenceRow gutter="32px" layoutLarge="1fr 1fr 1fr" marginBottom="18px">
        <TextField placeholder="" name={`occurrences.${index}.name`} label="Occurrence Name" maxLength={110} required />
        <TextField placeholder="" name={`occurrences.${index}.location`} label="Loss Location" />
        <DatePicker name={`occurrences.${index}.claimDate`} label="Date of Loss" showCalenderIcon inputIconPosition="after" />
      </OccurrenceRow>
      {hasInsData && (
        <OccurrenceRow gutter="32px" layoutLarge="1fr 1fr 1fr" marginBottom="18px">
          <InsInfo text={occurrence.claimNumber} label="Claim Number" />
          <InsInfo text={occurrence.insuranceYear} label="Insurance Year" />
          <InsInfo text={occurrence.interest} label="Interest" />
        </OccurrenceRow>
      )}
    </div>
  );
};
