import { ErrorBoundary } from '@instech/components';
import { SWRConfig } from 'swr';
import {
  createRoutesFromElements, Route, RouterProvider
} from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import { CurrentRoleProvider } from './common/authentication/CurrentRoleContext';
import { ModalHandler } from './common/modules/Modal';
import { TraversalOrderProvider } from './components/pages/claim/ClaimStatementPage/InvoiceTable/core/TraversalContext';
import { ErrorPage } from './components/pages/error/ErrorPage';
import { FeatureFlagProvider } from './common/providers/featureFlagProvider';
import { getJsonAsync } from './services/client';
import { PromptUnsavedChangesV6 } from './common/components/Form/PromptUnsavedChanges';
import { RootRouting } from './common/authentication/RootRouting';
import { useWebsiteDown } from './common/providers/websiteDownProvider';
import { DownForMaintenancePage } from './components/pages/error/DownForMaintenancePage';
import { AuthenticationProvider } from './common/authentication/AuthenticationProvider';

const swrConfig = {
  revalidateOnFocus: false,
  errorRetryCount: 3,
  fetcher: getJsonAsync
};

export const AppContent = () => (
  <ErrorBoundary component={ErrorPage}>
    <SWRConfig value={swrConfig}>
      <FeatureFlagProvider>
        <CurrentRoleProvider>
          <PromptUnsavedChangesV6>
            <ModalHandler>
              <TraversalOrderProvider>
                <RootRouting />
              </TraversalOrderProvider>
            </ModalHandler>
          </PromptUnsavedChangesV6>
        </CurrentRoleProvider>
      </FeatureFlagProvider>
    </SWRConfig>
  </ErrorBoundary>
);

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') ?? undefined;
const browserRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/*" element={<AppContent />} />
  ),
  { basename: baseUrl }
);

export const App = () => {
  const downForMaintenance = useWebsiteDown();

  if (downForMaintenance === undefined) {
    return null;
  }

  if (downForMaintenance) {
    if (window.location.pathname !== '/') {
      window.location.href = '/';
    }
    return <DownForMaintenancePage />;
  }

  return (
    <AuthenticationProvider>
      <RouterProvider router={browserRouter} />
    </AuthenticationProvider>
  );
};
