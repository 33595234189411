import {
  Breadcrumbs, breadcrumbVesselName, reusableSteps
} from '@/common/components/Breadcrumbs';
import { ExtendedPageWrapper } from '@/common/layout/Page';
import { StickyPageContent } from '@/common/layout/StickyPage';
import { HeadingWrapper, StickyPageHeading } from '@/common/layout/PageLayout';
import { useTypedModal } from '@/common/modules/Modal';
import { ClosedClaimStatementWarning } from '@/components/shared/Warnings/ClosedClaimStatementWarning';
import { useCurrentUser } from '@/services/userInfoServices';
import {
  setOwnersWorkComplete, useClaimStatement, useIsClosed
} from '@/services/claimStatementServices';
import {
  deleteInvoice, useInvoicesSummary, useInvoicesOverview, useSubmittedInvoicesOverview
} from '@/services/invoice/invoiceServices';
import { Guid, UserInfoDto } from '@/types';
import {
  getInvoiceStateFromOwnerToCH, shouldRemoveOwnersWorkComplete, transferInvoicesInGroups
} from '@/utils/invoicesTransfer';
import {
  MenuOption, PageLoader, SlimButton
} from '@instech/components';
import { Download, Plus } from '@instech/icons';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useSWRConfig } from 'swr';
import { roles } from '@/utils/constants';
import { useCurrentRole } from '@/common/authentication/CurrentRoleContext';
import { updateParticipantsWithRole } from '@/services/participantsService';
import {
  useEffect, useRef, useState
} from 'react';
import { ClaimDetails } from '../core/ClaimDetails';
import { ContactsPane } from './ContactsPane/ContactsPane';
import { InvoiceTableOwner } from './InvoiceTable';
import { deleteInvoiceModal } from './InvoiceTable/modal/DeleteInvoiceModal';
import { defaultTransferOwnerToClaimsHandlerProps, transferOwnerToClaimsHandlerModal } from './InvoiceTable/modal/TransferOwnerToClaimsHandlerModal';
import { SummaryPane } from './SummaryPane/SummaryPane';
import { exportAndDownload } from './modal/ExportClaimStatementModal';

const PageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "details summary contacts";
  column-gap: 48px;
  row-gap: 40px;
`;
const GridPlacement = styled.div<{ area?: string }>`
  grid-area: ${props => props.area};
`;

const TableArea = styled.div`
  padding: 0px 40px 40px;
`;

const SlimButtonStyled = styled(SlimButton)`
  margin-left: auto;
  margin-right: 20px;
`;

const SubmittedInvoiceTableWrapper = styled.div`
  margin-top: 20px;
`;

const showSummary = { ownerSummary: true, surveyorSummary: false, adjusterSummary: false };

export const OwnerClaimStatementPage = () => {
  const { claimStatementId } = useParams();
  const role = useCurrentRole();
  const currentUser = useCurrentUser();
  const { data, mutate: refreshClaimStatement } = useClaimStatement(claimStatementId);
  const { data: summary, mutate: refreshSummary } = useInvoicesSummary(claimStatementId);
  const { cache } = useSWRConfig();
  const { data: invoices, mutate: refreshOverview } = useInvoicesOverview(claimStatementId);
  const { data: submittedInvoices, mutate: refreshSubmittedInvoices } = useSubmittedInvoicesOverview(claimStatementId);
  const navigate = useNavigate();
  // The transfer function will be provided when the modal is opened in handleSubmitToClaimsHandler
  const { open: openModal } = useTypedModal(transferOwnerToClaimsHandlerModal(defaultTransferOwnerToClaimsHandlerProps));
  // The onDelete function will be provided when the modal by the context menu
  const { open: openDeleteModal } = useTypedModal(deleteInvoiceModal({ onDelete: async () => { } }));
  const isClaimStatementClosed = useIsClosed(data);
  const [exportButtonEnabled, setExportButtonEnabled] = useState(true);

  const exportButtonDisabledTimeoutId = useRef<any>();

  useEffect(() => () => clearTimeout(exportButtonDisabledTimeoutId.current), []);

  if (!data || !invoices || !submittedInvoices || !summary) return <PageLoader />;

  const refreshInvoiceData = async () => {
    void refreshOverview();
    void refreshSubmittedInvoices();
    void refreshSummary();
  };

  const handleTransfer = async (invoicesIdsToTransfer: string[]) => {
    const invoicesToTransfer = invoices!.invoices.filter(x => invoicesIdsToTransfer.includes(x.id));
    const removeOwnersWorkComplete = shouldRemoveOwnersWorkComplete(invoicesToTransfer, data.ownersWorkComplete);

    await transferInvoicesInGroups(
      claimStatementId!,
      invoicesToTransfer,
      getInvoiceStateFromOwnerToCH,
      refreshInvoiceData,
      cache,
      undefined
    );

    if (removeOwnersWorkComplete) {
      await refreshClaimStatement({ ...data!, ownersWorkComplete: false });
    }
  };

  const navigateToInvoice = (invoiceId: Guid) => {
    navigate(`invoices/${invoiceId}`);
  };

  const navigateToUpload = () => {
    navigate(`/claimstatements/${claimStatementId}/invoices/upload`);
  };

  const downloadInvoices = async () => {
    setExportButtonEnabled(false);
    exportButtonDisabledTimeoutId.current = setTimeout(() => setExportButtonEnabled(true), 3000);
    exportAndDownload(claimStatementId!, 'allInvoices', false);
  };

  const handleDelete = async (invoiceId: Guid) => {
    if (claimStatementId === undefined) {
      return;
    }

    const success = await deleteInvoice(claimStatementId, invoiceId) === 'success';
    if (success) {
      await refreshOverview({
        ...invoices, invoices: invoices.invoices.filter(invoice => invoice.id !== invoiceId),
      });
      await refreshSummary();
    }
  };

  const handleMarkOwnersWorkComplete = async () => {
    await setOwnersWorkComplete(claimStatementId);
    await refreshClaimStatement();
  };

  const handleSubmitToClaimsHandler = async (invoiceId: Guid) => {
    const invoice = invoices.invoices.find(i => i.id === invoiceId);
    openModal({
      transfer: async () => {
        void await handleTransfer([invoiceId]);
      },
      numberOfIncompleteInvoices: invoice?.record.isComplete ? 0 : 1,
      numberOfInvoices: 1
    });
  };

  const contextMenuOptions = (invoiceId: Guid): MenuOption[] => [
    {
      label: 'Open',
      onClick: () => navigateToInvoice(invoiceId)
    },
    {
      label: 'Submit to Claims Handler',
      onClick: isClaimStatementClosed ? undefined : () => handleSubmitToClaimsHandler(invoiceId)
    },
    { // NB: Delete option should be fixed last on the list
      label: 'Delete',
      onClick: isClaimStatementClosed ? undefined : () => openDeleteModal({ onDelete: () => handleDelete(invoiceId) })
    },
  ];

  const handleSelectParticipants = async (participants: UserInfoDto[]) => {
    await updateParticipantsWithRole({ claimStatementId, role, participants });
    if (!participants.some(p => p.id === currentUser?.id)) {
      navigate('/');
      return;
    }
    await refreshClaimStatement();
  };

  const breadcrumbSteps = [reusableSteps.myDashboardStep];
  const currentPage = breadcrumbVesselName(data.vesselName, data.isExperimental);

  return (
    <ExtendedPageWrapper>
      <StickyPageHeading>
        <HeadingWrapper>
          <Breadcrumbs
            steps={breadcrumbSteps}
            current={currentPage} />
          <SlimButtonStyled startIcon={<Download />} disabled={!exportButtonEnabled} variant="secondary" onClick={downloadInvoices}>
            EXPORT TO EXCEL
          </SlimButtonStyled>
          <SlimButton startIcon={<Plus />} disabled={isClaimStatementClosed} onClick={navigateToUpload}>
            UPLOAD INVOICES
          </SlimButton>
        </HeadingWrapper>
      </StickyPageHeading>
      <StickyPageContent noTopPadding>
        {isClaimStatementClosed && <ClosedClaimStatementWarning />}
        <PageGrid>
          <GridPlacement area="details">
            <ClaimDetails data={data} />
          </GridPlacement>
          <GridPlacement area="summary">
            <SummaryPane
              summary={summary}
              showSummary={showSummary}
              claimStatementSettings={data.claimStatementSettings}
              userRole={roles.Owner}
              showSubTitle={false}
              isReview />
          </GridPlacement>
          <GridPlacement area="contacts">
            <ContactsPane data={data}
              onSelect={handleSelectParticipants} />
          </GridPlacement>
        </PageGrid>
      </StickyPageContent>
      <TableArea>
        <InvoiceTableOwner
          title="Drafts"
          data={invoices}
          onClick={navigateToInvoice}
          onSubmit={handleTransfer}
          contextMenuOptions={contextMenuOptions}
          ownersWorkComplete={data.ownersWorkComplete}
          isClaimStatementClosed={isClaimStatementClosed} />
        <SubmittedInvoiceTableWrapper>
          <InvoiceTableOwner
            title="Submitted to Claims Handler"
            data={submittedInvoices}
            onClick={navigateToInvoice}
            selectableRows={false}
            allowEmpty
            onMarkAsComplete={handleMarkOwnersWorkComplete}
            ownersWorkComplete={data.ownersWorkComplete}
            isClaimStatementClosed={isClaimStatementClosed}
            submittedTable />
        </SubmittedInvoiceTableWrapper>
      </TableArea>
    </ExtendedPageWrapper>
  );
};
