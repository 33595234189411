import { Header } from '@/common/modules/Header';
import { FCWC } from '@/types';
import styled from 'styled-components';

export const PageWrapper = styled.div`
  background-color:  ${props => props.theme.whiteBlue};
  min-height: 100vh;
`;

const PagePadding = styled.div`
  padding-top: 100px;
`;

export const ExtendedPageWrapper = styled.div`
  width: fit-content;
  background-color:  ${props => props.theme.whiteBlue};
`;

/**
 * Component to be used as highest-order wrapper for ./**Page.tsx components,
 * as a container for the contents within.
 */
export const PageContent = styled.div <{ noTopPadding?: boolean }>`
  height: 100%;
  padding: 24px 40px 40px;
  padding-top: ${props => props.noTopPadding && '0px'};
`;

/**
 * Content within will display normally, then be hidden from
 * view when the user opens the print dialog.
 */
export const HiddenInPrint = styled.div`
  display: contents;
  @media print {
    display: none !important;
  }
`;

/**
 * Content within will be hidden from view, and only show when
 * the user opens the print dialog.
 */
export const ShowInPrint = styled.div`
  display: none;
  @media print {
    display: contents !important;
  }
`;

interface HeaderProps {
  emptyUserMenu?: boolean;
  showPortalSelector?: boolean;
  showUserMenu?: boolean;
  disableLink?: boolean;
  showTabs?: boolean;
}

interface PageProps {
  headerProps?: HeaderProps;
}
/**
 * Component used globally in the app, as part of the AppRouting structure,
 * applying the correct backdrop colour and adding a universal header to
 * all application pages.
 */
export const Page: FCWC<PageProps> = ({ children, headerProps }) => (
  <PageWrapper>
    <Header {...(headerProps && { ...headerProps })} />
    <PagePadding>
      {children}
    </PagePadding>
  </PageWrapper>
);
