import React, { FC, useMemo } from 'react';
import {
  DatePicker,
  SlimButton,
  SlimButtonProps,
  TextField
} from '@instech/components';
import { FormRow } from '@/common/components/Form/FormRow/FormRow';
import {
  MoveLayerDown, Refresh, Trash
} from '@instech/icons';
import styled from 'styled-components';
import { Occurrence } from '@/types';
import { InfoWithLabel } from '@/common/components/Text';
import { useTypedModal } from '@/common/modules/Modal';
import { Form, Formik } from 'formik';
import { createOccurrenceFromInsModal } from '@/components/pages/claim/core/ClaimStatementForm/Occurrences';
import {
  AutosaveTrigger,
  dispatchAutosaveStartedEvent
} from '@/common/components/Autosave';
import { dispatchAutosaveFinishedEvent } from '@/common/components/Autosave/autosaveCallback';
import {
  useOpenConfirmDeleteOccurrenceModal,
  useOpenTransferAndConfirmDeleteOccurrenceModal
} from '@/components/pages/claim/EditClaimStatementPage/core/Occurrences/modals';
import { TransferAllocatedCostRequest } from '@/services/occurrenceServices';
import { occurrenceValidationSchema } from './occurrenceValidationSchema';

interface TextButtonProps extends SlimButtonProps {
  icon: any;
  text: string;
  disabled?: boolean;
  onClick?: () => void;
}

const StyledButton = styled(SlimButton)`
  & span {
    font-size: 14px;
  }
`;

const TextButton: FC<TextButtonProps> = ({
  icon,
  text,
  ...props
}) => (
  <StyledButton variant="tertiary" startIcon={icon} {...props}>
    {text}
  </StyledButton>
);

interface SyncButtonProps {
  onGetOccurrenceDataFromIns?: () => void;
  hasInsData: boolean;
}

const SyncButton: FC<SyncButtonProps> = ({
  hasInsData,
  onGetOccurrenceDataFromIns
}) => {
  if (hasInsData) {
    return (
      <TextButton disabled icon={<Refresh width="18px" height="18px" />} text="RE-SYNC" />
    );
  }
  return (
    <TextButton onClick={onGetOccurrenceDataFromIns} icon={<MoveLayerDown width="18px" height="18px" />} text="GET CLAIM FROM INS+" />
  );
};

const Title = styled.div`
  display: flex;
  align-items: baseline;
  color: ${props => props.theme.marineBlue};
  justify-content: space-between;
  font-size: 16px;
  padding-bottom: 5px;
  border-bottom: ${props => `2px ${props.theme.marineBlue60} solid`};
  font-weight: bold;
  margin-bottom: 20px;
`;

const OccurrenceRow = styled(FormRow)`
  align-items: center;
`;

const InsInfo = styled(InfoWithLabel)`
  color: ${props => props.theme.marineBlue};
`;

interface ItemProps {
  index: number,
  occurrences: Occurrence[],
  disabled: boolean,
  currency: string,
  isExperimentalClaimStatement: boolean,
  onClickUpdateOccurrence: (occurrence: Occurrence) => Promise<void>,
  onClickDeleteOccurrence: (occurrence: Occurrence) => Promise<void>,
  onTransferInvoiceSplits: (occurrenceId: string, request: TransferAllocatedCostRequest) => Promise<void>,
  hasAllocatedCosts: boolean | undefined;
}

export const OccurrenceItem: FC<ItemProps> = ({
  index,
  occurrences,
  disabled,
  currency,
  isExperimentalClaimStatement,
  onClickUpdateOccurrence,
  onClickDeleteOccurrence,
  onTransferInvoiceSplits,
  hasAllocatedCosts
}) => {
  const otherOccurrences = occurrences
    .map((occurrence, i) => ({ value: occurrence, index: i }))
    .filter(x => x.index !== index);

  const occurrence = occurrences[index];

  const initialValues = useMemo(() => ({
    name: occurrence.name,
    location: occurrence.location,
    claimDate: occurrence.claimDate
  }), [occurrence]);
  const hasInsData = occurrence.claimNumber !== undefined && occurrence.claimNumber !== null;

  const handleSubmit = async (form: typeof initialValues) => {
    dispatchAutosaveStartedEvent();
    await onClickUpdateOccurrence({
      ...occurrence,
      ...form,
    });
    dispatchAutosaveFinishedEvent();
  };

  const { open: openUpdateOccurrenceWithDataFromInsModal } = useTypedModal(createOccurrenceFromInsModal({
    modalTitle: 'Get Claim from INS+',
    occurrenceOrderNumber: index,
    currency,
    isExperimentalClaimStatement,
    onSubmit: async occurrenceFromClaim => {
      const updatedOccurrence: Occurrence = {
        ...occurrenceFromClaim,
        id: occurrence.id,
      };
      await onClickUpdateOccurrence(updatedOccurrence);
    },
  }));

  const { open: openTransferAndConfirmDeleteModal } = useOpenTransferAndConfirmDeleteOccurrenceModal({
    deleteOccurrence: () => onClickDeleteOccurrence(occurrence),
    transferAllocations: (request: TransferAllocatedCostRequest) => onTransferInvoiceSplits(occurrence.id, request),
    otherOccurrences,
  });
  const { open: openConfirmDeleteModal } = useOpenConfirmDeleteOccurrenceModal({
    deleteOccurrence: () => onClickDeleteOccurrence(occurrence)
  });

  const handleClickDeleteOccurrence = () => {
    if (hasAllocatedCosts === undefined) {
      return;
    }

    if (hasAllocatedCosts) {
      openTransferAndConfirmDeleteModal();
    } else {
      openConfirmDeleteModal();
    }
  };

  const isDeleteDisabled = disabled || hasAllocatedCosts === undefined;
  return (
    <div>
      <Title>
        <div>
          Occurrence #
          {index + 1}
        </div>
        <div>
          <SyncButton hasInsData={hasInsData} onGetOccurrenceDataFromIns={openUpdateOccurrenceWithDataFromInsModal} />
          <TextButton
            icon={<Trash />}
            loading={hasAllocatedCosts === undefined}
            text="DELETE OCCURRENCE"
            disabled={isDeleteDisabled}
            onClick={handleClickDeleteOccurrence} />
        </div>
      </Title>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={occurrenceValidationSchema}>
        <Form>
          <AutosaveTrigger />
          <OccurrenceRow gutter="32px" layoutLarge="1fr 1fr 1fr" marginBottom="18px">
            <TextField placeholder="" name="name" label="Occurrence Name" maxLength={110} required />
            <TextField placeholder="" name="location" label="Loss Location" />
            <DatePicker
              name="claimDate"
              label="Date of Loss"
              showCalenderIcon
              inputIconPosition="after" />
          </OccurrenceRow>
        </Form>
      </Formik>
      {hasInsData && (
        <OccurrenceRow gutter="32px" layoutLarge="1fr 1fr 1fr" marginBottom="18px">
          <InsInfo text={occurrence.claimNumber} label="Claim Number" />
          <InsInfo text={occurrence.insuranceYear} label="Insurance Year" />
          <InsInfo text={occurrence.interest} label="Interest" />
        </OccurrenceRow>
      )}
    </div>
  );
};
