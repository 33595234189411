import { NumberParser } from '@internationalized/number';
import { sanitizeFormattedNumber, truncateDecimals } from './number';

const nordicDecimalOnly = /^-?\d+(,\d+)?$/; // 1000,23
const nordicDotAsThousand = /^-?\d+(\.\d{3})+(,\d+)?$/; // 1.000,23
const nordicSpaceAsThousand = /^-?\d+( \d{3})+(,\d+)?$/; // 1 000,23

const englishDecimalOnly = /^-?\d+(\.\d+)?$/; // 1000.23
const englishThreeDecimals = /^-?\d+(\.\d{3})$/; // 1000.234
const englishCommaAsThousand = /^-?\d+(,\d{3})+(\.\d+)?$/; // 1,000.23
const englishSpaceAsThousand = /^-?\d+( \d{3})+(\.\d+)?$/; // 1 000.23

const numberFormatIsNordic = (value: string, skipThousandSeparatorTest: boolean) => {
  // system-calculated numbers use dot as decimal separator, so for small calculated numbers with 3 decimals we need
  // to either skip the thousand separator test or verify that the number is not an English decimal format (ex. 1000.123)
  const dotIsNordicThousand = !skipThousandSeparatorTest && nordicDotAsThousand.test(value) && !englishThreeDecimals.test(value);
  return dotIsNordicThousand || nordicDecimalOnly.test(value) || nordicSpaceAsThousand.test(value);
};

const numberIsEnglishFormat = (value: string) => (
  englishDecimalOnly.test(value) || englishCommaAsThousand.test(value) || englishSpaceAsThousand.test(value)
);

export const getParsedDecimalAsStringWithGivenPrecision = (value: string, precision: number = 2, skipThousandSeparatorTest: boolean = false): string => {
  const englishNumberParser = new NumberParser('en-US', { style: 'decimal' });
  const nordicNumberParser = new NumberParser('da-DK', { style: 'decimal' });
  const trimmedValue = value.replace(/[^0-9.,-]+/g, '');

  if (numberFormatIsNordic(trimmedValue, skipThousandSeparatorTest)) {
    const decimalValue = nordicNumberParser.parse(trimmedValue);
    return truncateDecimals(Number(decimalValue), precision).toString();
  }

  if (numberIsEnglishFormat(trimmedValue)) {
    const decimalValue = englishNumberParser.parse(trimmedValue);
    return truncateDecimals(Number(decimalValue), precision).toString();
  }

  return sanitizeFormattedNumber(trimmedValue);
};
