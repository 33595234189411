import { InvoiceReplaceRequestStatus } from '@/services/invoice/invoiceServices';
import {
  ClaimStatementInvoiceId, Invoice, UploadNoFileName
} from '@/types';
import { ButtonGroup } from '@instech/components';
import { useState } from 'react';
import styled from 'styled-components';
import { PdfViewer } from '@/common/components/File/PdfViewer';
import { useSupportingDocuments } from '@/services/supportingDocumentsServices';
import { AttachmentsPane } from './AttachmentsPane/AttachmentsPane';
import { InvoiceActionsPane } from './InvoiceActionsPane';
import { InvoiceDownload } from './InvoiceDownload';
import { PaymentConfirmationPane } from './PaymentConfirmationPane/PaymentConfirmationPane';
import { InvoiceTab } from './core/InvoiceTab';

// TODO: refactor into external file together with uploader valid filetypes?
const validImages = [
  'jpg',
  'jpeg',
  'png',
  'jfif'
];

const getFiletype = (url: string) => url.split('.').pop()?.toLowerCase() || '';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 30px;
`;

const ImageBox = styled.div`
  height: 100%;
  margin-bottom: 30px;
  // cheaply clamp height based on size to 4:3 A-series document
  aspect-ratio: 4 / 3;
  overflow-y: auto;
  border: 1px solid ${props => props.theme.marineBlue};
`;

const Image = styled.img`
  display: block;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 429px;
`;

const TabPane = styled.div<{ show: boolean }>`
  display: ${props => props.show ? 'flex' : 'none'};
  flex-direction: column;
  height: 100%;
  margin-bottom: 30px;
`;

const StyledText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.marineBlue};
  line-height: 29px;
  font-size: 24px;
  font-weight: 700;
  height: 100%;
  background-color: ${({ theme }) => theme.marineBlue5};
`;

const getCompositeId = (invoice: Invoice): ClaimStatementInvoiceId => ({
  claimStatementId: invoice.claimStatementId, invoiceId: invoice.id
});

type InvoiceTabType = 'invoice' | 'paymentConfirmation' | 'attachments';

interface InvoiceActionPaneProps {
  replaceInvoice: (invoice: File) => Promise<InvoiceReplaceRequestStatus>;
  refresh: () => void;
}

interface Props {
  invoice: Invoice;
  invoiceActionPane?: InvoiceActionPaneProps;
  replaceable?: boolean;
  ownsLock: boolean;
  imageUrl?: string;
  isClaimStatementClosed: boolean;
}
export const InvoiceDisplay = ({ invoice, invoiceActionPane, replaceable, ownsLock, imageUrl = '', isClaimStatementClosed }: Props) => {
  const [activePane, setActivePane] = useState<InvoiceTabType>('invoice');

  const compositeInvoiceId = getCompositeId(invoice);
  const { isPending, attachments, paymentConfirmations } = useSupportingDocuments(compositeInvoiceId);

  const isImage = validImages.includes(getFiletype(invoice.name));
  const isPdf = getFiletype(invoice.name) === 'pdf';
  const isUploadWithFile = !!invoice?.path;

  return (
    <Wrapper>
      <ButtonGroup buttonGap="0px">
        <InvoiceTab
          title="Invoice"
          width="140px"
          isActiveTab={activePane === 'invoice'}
          onClick={() => setActivePane('invoice')}
        />
        {isUploadWithFile && (
          <InvoiceTab
          title="Payment Confirmation"
          width="210px"
          count={paymentConfirmations.length}
          isLoading={isPending}
          isActiveTab={activePane === 'paymentConfirmation'}
          onClick={() => setActivePane('paymentConfirmation')} />
        )}
        <InvoiceTab
            title="Attachments"
            width="148px"
            count={attachments.length}
            isLoading={isPending}
            isActiveTab={activePane === 'attachments'}
            onClick={() => setActivePane('attachments')} />
      </ButtonGroup>
      <TabPane show={activePane === 'invoice'}>
        <ImageBox>
          {isImage && <Image alt="Invoice" src={imageUrl} />}
          {isPdf && <PdfViewer src={imageUrl} />}
          {!isUploadWithFile && (<StyledText>{`${UploadNoFileName}`}</StyledText>)}
        </ImageBox>
        {isUploadWithFile && (
        <ButtonWrapper>
          {(ownsLock && replaceable && invoiceActionPane) && <InvoiceActionsPane disabled={isClaimStatementClosed} {...invoiceActionPane} />}
          <InvoiceDownload invoiceUrl={imageUrl} />
        </ButtonWrapper>
        )}
      </TabPane>
      <TabPane show={activePane === 'paymentConfirmation'}>
        <PaymentConfirmationPane
          compositeInvoiceId={compositeInvoiceId}
          paymentConfirmations={paymentConfirmations}
          isClaimStatementClosed={isClaimStatementClosed}
        />
      </TabPane>
      <TabPane show={activePane === 'attachments'}>
        <AttachmentsPane
          compositeInvoiceId={compositeInvoiceId}
          attachments={attachments}
          isClaimStatementClosed={isClaimStatementClosed}
        />
      </TabPane>
    </Wrapper>
  );
};
