import { useEffect } from 'react';
import { AxiosResponse } from 'axios';
import {
  Invoice, InvoiceUploadStatus, StagedInvoice,
  UploadNoFileName
} from '@/types';

export const useInvoiceResponses = (
  invoiceResponses: [string, Promise<AxiosResponse<Invoice, any>>][],
  setStagedInvoices: (invoices: StagedInvoice[]) => void,
  stagedInvoices: StagedInvoice[],
  setInvoiceResponses: (responses: [string, Promise<AxiosResponse<Invoice, any>>][]) => void
) => useEffect(() => {
  const handleResponses = async () => {
    if (invoiceResponses.length === 0) {
      return;
    }
    const handledResponses = await Promise.all(invoiceResponses.map(async ([invoiceId, responsePromise]) => {
      try {
        const invoiceResponse = await responsePromise;
        return { id: invoiceId, status: invoiceResponse.data.uploadStatus, order: invoiceResponse.data.record.order };
      } catch (e: any) {
        const status = e.response?.status;

        if (status === 413) {
          return { id: invoiceId, status: 'SizeTooLarge', order: 0 };
        }
        return { id: invoiceId, status: 'Failed', order: 0 };
      }
    }));
    const updatedInvoiceMap: { [id: string]: { status: InvoiceUploadStatus, order?: number; } } = handledResponses.reduce((invoices, invoice) =>
      ({ ...invoices, [invoice.id]: { status: invoice.status, order: invoice.order } }), {});
    const updatedStagedInvoices = stagedInvoices.map(invoice => ({
      ...invoice,
      name: invoice?.name ?? `${UploadNoFileName} - ${invoice.id}`,
      uploadStatus: updatedInvoiceMap[invoice.id]?.status ?? invoice.uploadStatus,
      order: updatedInvoiceMap[invoice.id]?.order
    }));

    setStagedInvoices(updatedStagedInvoices);
    setInvoiceResponses([]);
  };
  void handleResponses();
}, [invoiceResponses, setInvoiceResponses, setStagedInvoices, stagedInvoices]);
